import React, { useCallback, useContext } from 'react'
import { getFirebase } from '@babelcoder/gatsby-plugin-firebase'
import { navigate } from 'gatsby'

import { GlobalStateContext } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer'
import AuthGuard, {
  TYPES,
} from '@babelcoder/gatsby-theme-base/src/components/AuthGuard'
import AuthForm from '@components/auth/AuthForm'
import authErrors from '@utils/auth-errors'

function SignUpPage({ location: { state } }) {
  const { dispatch } = useContext(GlobalStateContext)

  const signup = useCallback(
    async ({ email, password }) => {
      try {
        const firebase = await getFirebase()
        const { user } = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
        await user.sendEmailVerification()
        navigate('/auth/complete', {
          replace: true,
          state: { type: 'sign-up' },
        })
      } catch (error) {
        let message =
          authErrors[error.code] ||
          'เกิดข้อผิดพลาดในการสมัครสมาชิก โปรดลองอีกครั้ง'

        dispatch({
          type: actions.showPopupMessage,
          popupMessage: {
            type: 'danger',
            title: 'เกิดข้อผิดพลาด',
            message,
          },
        })
      }
    },
    [dispatch]
  )

  return (
    <AuthGuard type={TYPES.AUTH_DENY}>
      <AuthForm
        type="SIGN_UP"
        onSubmit={signup}
        title="สวัสดี, มาเป็นครอบครัว Babel Coder กันเถอะ!"
        submitText="สมัครสมาชิก"
        email={state?.email || ''}
      ></AuthForm>
    </AuthGuard>
  )
}

export default SignUpPage
